/* You can add global styles to this file, and also import other style files */

/*#region COLORS */
:root {
  /* Colors */
  --purplex11: #991eeb;
  --smokyblack: #141314;
  --jet: #373737;
  --taupegray: #7f7b82;
  --isabelline: #f5f1ed;
  --platinum: #e6e6e6;
  --gold: #a58c5f;

  /* Assigned color variables */
  --primary: var(--smokyblack);
  --secondary: var(--jet);
  --accent: var(--purplex11);

  /* Hero Image colors */
  --hero-txt: white;

  /* Quest box colors */
  --quest-bg: var(--isabelline);
  --quest-txt: black;
  --quest-brd: var(--gold);

  /* Step colors */
  --step-bg: var(--smokyblack);
  --step-txt: white;
  --step-brd: var(--accent);
}

/*#endregion*/

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--jet);
}

.table-50 th,
.table-50 td {
    width: 50%;
    padding: 10px;
}

.table-50 * {
    word-wrap: normal;
  }

.btn-toggle {
  width: 100%;
}

.centered {
  text-align: center;
  display: block;
  margin: 0 auto;
}

.job-icon,
.step-icon {
  width: 30px;
  display: block;
  margin: 0 auto;
}

.relic-calc-table {
  text-align: center;
}

.job-list {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.button-list {
  display: block;
  text-align: center;
}
.button-list button {
  margin-left: 15px;
}

.relic-calc-table th,
.relic-calc-table td {
  max-width: 5.26%;
}

.job-list button.selected {
  background-color: green;
}

.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 500px;
  color: var(--hero-txt);
}

.right {
    float: right;
}